import { Injectable } from '@angular/core';
import { SCORE } from '../../app/modules/threat-check/constant';
import { AppEnvironmentService } from '@ti/app.environment.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class ThreatCheckService {
  private baseUrl = this.env.threatCheck;

  constructor(
    private env: AppEnvironmentService,
    private http: HttpClient,
    private translate: TranslateService,
    private toast: ToastrService,
  ) {}

  submitReport(payload) {
    return this.http.post(`${this.baseUrl}/request-report`, payload);
  }

  submit(payload) {
    return this.http.post(`${this.baseUrl}/request-upgrade`, payload);
  }

  calculate(data) {
    return this.http.post(`${this.baseUrl}/calculate`, data);
  }

  getAsset(report: string) {
    return this.http.get(`${this.baseUrl}/asset?report=${report}&lang=${this.translate.currentLang}`);
  }

  getCredential(report: string) {
    return this.http.get(`${this.baseUrl}/credential?report=${report}&lang=${this.translate.currentLang}`);
  }

  getDataBreach(report: string) {
    return this.http.get(`${this.baseUrl}/databreach?report=${report}&lang=${this.translate.currentLang}`);
  }

  getPhishing(report: string) {
    return this.http.get(`${this.baseUrl}/phishing?report=${report}&lang=${this.translate.currentLang}`);
  }

  getImpersonate(report: string) {
    return this.http.get(`${this.baseUrl}/impersonate?report=${report}&lang=${this.translate.currentLang}`);
  }

  getCompromisedSystem(report: string) {
    return this.http.get(`${this.baseUrl}/compromised-system?report=${report}&lang=${this.translate.currentLang}`);
  }

  getPortAnomaly(report: string) {
    return this.http.get(`${this.baseUrl}/port-anomaly?report=${report}&lang=${this.translate.currentLang}`);
  }

  getWebEncryption(report: string) {
    return this.http.get(`${this.baseUrl}/web-encryption?report=${report}&lang=${this.translate.currentLang}`);
  }

  getHttpHeaderSecurity(report: string) {
    return this.http.get(`${this.baseUrl}/http-header-security?report=${report}&lang=${this.translate.currentLang}`);
  }

  getTextScore(score: number): string {
    let textScore = SCORE.GOOD;
    if (score >= 8.5 && score <= 10) {
      textScore = SCORE.CRITICAL;
    } else if (score >= 7 && score < 8.5) {
      textScore = SCORE.HIGH;
    } else if (score >= 4 && score < 7) {
      textScore = SCORE.MEDIUM;
    } else if (score >= 2 && score < 4) {
      textScore = SCORE.LOW;
    }
    return textScore;
  }

  getBadgeImage(score: number): string {
    let image = 'check.svg';
    if (score >= 8.5 && score <= 10) {
      image = 'warning-red.svg';
    } else if (score >= 7 && score < 8.5) {
      image = 'cross-high.svg';
    } else if (score >= 4 && score < 7) {
      image = 'cross-medium.svg';
    } else if (score >= 2 && score < 4) {
      image = 'cross-low.svg';
    }
    return image;
  }

  roundToFloor(number: number): string {
    const num = number.toString();
    const rest = num.substring(1, num.length);
    const round = number - Number(rest);
    return round.toString();
  }

  getErrorMessage(error: string): void {
    switch (error) {
      case 'ErrEnterpriseOrganization':
        this.toast.error(
          this.translate.instant('ERROR_ENTERPRISE_ORGANIZATION'),
          this.translate.instant('ERROR_THREAT_CHECK'),
        );
        break;
      case 'ErrInvalidRecaptcha':
      case 'ErrNotAHuman':
        this.toast.error(this.translate.instant('ERROR_INVALID_CAPTCHA'), this.translate.instant('ERROR_THREAT_CHECK'));
        break;
      case 'ErrPersonalEmail':
      case 'ErrInvalidEmail':
        this.toast.error(this.translate.instant('ERROR_PERSONAL_EMAIL'), this.translate.instant('ERROR_THREAT_CHECK'));
        break;
      case 'ErrInvalidDomain':
      case 'ErrNotOrgnaization':
      case 'ErrBackList':
        this.toast.error(this.translate.instant('ERROR_BLACKLIST_EMAIL'), this.translate.instant('ERROR_THREAT_CHECK'));
        break;
      case 'ErrInvalidEmailDomain':
        this.toast.error(
          this.translate.instant('ERROR_INVALID_DOMAIN_EMAIL'),
          this.translate.instant('ERROR_THREAT_CHECK'),
        );
        break;
      case 'ErrNotSupport':
        this.toast.error(
          this.translate.instant('ERROR_NOT_SUPPORT'),
          this.translate.instant('ERROR_THREAT_CHECK'),
        );
        break;
      default:
        this.toast.error(this.translate.instant('ERROR_THREAT_CHECK'));
        break;
    }
  }
}
