import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TiScrollToService } from '@ti/ti-scroll-to.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SectionsService } from './sections.service';
import { AppEnvironmentService } from './app.environment.service';

@Component({
  selector: 'ti-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menu: any;
  // positions = [];
  // sizes = [];
  sessions = [
    'ti-customer-banner',
    'ti-customer-needs',
    'ti-features',
    'ti-service-packages',
    'ti-outstanding',
    'ti-carousel',
  ];
  title = 'angular landing';

  constructor(
    private router: Router,
    private translate: TranslateService,
    private scrollTo: TiScrollToService,
    private sectionsService: SectionsService,
    private env: AppEnvironmentService,
  ) {}

  ngOnInit() {
    const lang = localStorage.getItem('lang');
    // force use vi
    if (!lang || lang === 'en') {
      localStorage.setItem('lang', 'vi');
    }
    this.translate.use('vi');
    // if (lang) {
    //   this.translate.use(lang);
    // } else {
    //   console.log('here');
    //   localStorage.setItem('lang', 'vi');
    //   this.translate.use('vi');
    // }
    if (!this.env.enableThreatCheck && window.location.hash.includes('/threat-check')) {
      this.router.navigateByUrl('/');
    }
  }

  highlightMenu(position) {
    let anchor = 120;
    if (window.innerWidth > window.innerHeight) {
      anchor = 20;
    } else {
      if (window.innerWidth < 376) {
        anchor = 30;
      }
    }
    const menu = this.menu ? this.menu : document.getElementById('ti-navbar');
    if (position > anchor) {
      menu.classList.add('merge');
    } else {
      menu.classList.remove('merge');
    }
  }

  scrolled(pos) {
    this.highlightMenu(pos);

    this.sectionsService.sections.forEach((section, index) => {
      if (
        section.element?.nativeElement.getBoundingClientRect().y / window.innerHeight <= 0.3 &&
        this.scrollTo.currentView < index + 1
      ) {
        this.scrollTo.currentView = index;
      }

      if (pos + window.innerHeight >= document.getElementById('ti-main').scrollHeight) {
        this.scrollTo.currentView = this.sessions.length - 1;
      }
    });
  }

  scrolledUp(pos) {
    this.highlightMenu(pos);

    this.sectionsService.sections.forEach((section, index) => {
      if (pos === 0) {
        this.scrollTo.currentView = 0;
      } else if (
        section.element?.nativeElement?.getBoundingClientRect().y / window.innerHeight >= -0.3 &&
        this.scrollTo.currentView > index
      ) {
        console.log('index');

        this.scrollTo.currentView = index;
      }
    });
  }
}
