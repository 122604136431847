import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvironmentService } from '@ti/app.environment.service';

@Component({
  selector: 'ti-trial-alert',
  templateUrl: './trial-alert.component.html',
  styleUrls: ['./trial-alert.component.scss'],
})
export class TrialAlertComponent implements OnInit {
  enableRegister = false;
  constructor(private env: AppEnvironmentService, private translate: TranslateService) {}

  ngOnInit(): void {
    this.enableRegister = this.env.enableRegister;
  }
  freeTrial(): void {
    window.open(`${this.env.linkToRegister}?lang=${this.translate.currentLang}`, '_blank');
  }
}
