import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvironmentService } from '@ti/app.environment.service';
import { ItemMenu } from '@ti/models/menu.model';
import { TiScrollToService } from '@ti/ti-scroll-to.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { menu } from './menu';
import { MixpanelService } from '@ti/mixpanel.service';

@Component({
  selector: 'ti-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menu: ItemMenu[];
  type: string;
  curLang: string;
  isOpen = false;
  isDesktop = true;
  enableRegister = false;
  enableThreatCheck = true;
  constructor(
    private translate: TranslateService,
    private env: AppEnvironmentService,
    public scrollTo: TiScrollToService,
    private detect: DeviceDetectorService,
    private router: Router,
    private mixpanelService: MixpanelService,
  ) {
    this.menu = menu;
    this.enableThreatCheck = this.env.enableThreatCheck;
    if (!this.enableThreatCheck) {
      this.menu = this.menu.filter((m: ItemMenu) => m.link !== 'threat-check');
    }
    this.curLang = this.translate.currentLang || 'vi';
  }

  ngOnInit() {
    this.enableRegister = this.env.enableRegister;
    this.isDesktop = this.detect.isDesktop();
    window.addEventListener(
      'resize',
      () => {
        this.isDesktop = this.detect.isDesktop();
      },
      false,
    );
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isOpen = false;
        document.getElementById('ti-navbar').classList.remove('open');
        document.getElementById('ti-menu').classList.remove('show');
        window.scrollTo(0, 0);
      }
    });
  }

  goto(id) {
    this.scrollTo.currentView = id;
    if (!this.isDesktop) {
      document.getElementById('btn-toggler').click();
    }
  }

  changeLang(lang) {
    localStorage.setItem('lang', lang);
    this.translate.use(lang);
    this.curLang = lang;
  }

  navigateTo(route: string) {
    this.linkTrack(route === 'login' ? 'sign-in' : 'sign-up');
    window.open(
      `${route === 'login' ? this.env.linkToLogin : this.env.linkToRegister}?lang=${this.translate.currentLang}`,
      '_blank',
    );
  }

  toggleMenu() {
    this.isOpen = true;
    setTimeout(() => {
      this.isOpen = document.getElementById('ti-menu').className.includes('show');
    }, 400);
  }

  clickOutside() {
    document.getElementById('btn-toggler').click();
  }

  linkTrack(id: string) {
    this.mixpanelService.track(`Click ${id}`, {
      button_name: id,
      page_location: window.location.href,
    });
  }

  getFlagByLang(): string {
    switch (this.curLang) {
      case 'vi':
        return 'flag_vn.svg';
      case 'en':
        return 'flag_eng.svg';
      case 'jp':
        return 'flag_jp.svg';
      default:
        return 'vi';
    }
  }
}
