export const menu = [
  {
    id: 'pricing',
    label: 'PRICING',
    icon: '',
    link: 'pricing',
  },
  {
    id: 'threat-check',
    label: 'THREAT_CHECK',
    icon: '',
    link: 'threat-check',
  },
  {
    id: 'support',
    label: 'SUPPORT',
    icon: '',
    link: 'support',
  },
  {
    id: 'about-us',
    label: 'ABOUT_US',
    icon: '',
    link: 'about-us',
  },
];
