export const FEATURES = [
  {
    title: 'FEATURE_ITEM_TITLE_1',
    desc: 'FEATURE_1',
    image: '/assets/images/features/feature_1.jpg',
  },
  {
    title: 'FEATURE_ITEM_TITLE_2',
    desc: 'FEATURE_2',
    image: '/assets/images/features/feature_2.jpg',
  },
  {
    title: 'FEATURE_ITEM_TITLE_3',
    desc: 'FEATURE_3',
    image: '/assets/images/features/feature_3.jpg',
  },
  {
    title: 'FEATURE_ITEM_TITLE_4',
    desc: 'FEATURE_4',
    image: '/assets/images/features/feature_4.jpg',
  },
  {
    title: 'FEATURE_ITEM_TITLE_5',
    desc: 'FEATURE_5',
    image: '/assets/images/features/feature_5.jpg',
  },
];

export const FREE_TOOLS = [
  {
    icon: 'assets/images/free_tools/free_tool_1.png',
    desc: {
      vi: 'Tặng 01 Báo cáo kiểm tra rủi ro an ninh mạng của doanh nghiệp',
      en: 'Get 1 free Threat Check report',
    }
  },
  {
    icon: 'assets/images/free_tools/free_tool_2.png',
    desc: {
      vi: 'Miễn phí giám sát 1 tên miền, 1 địa chỉ IP và 1 từ khóa, 10 phần mềm & hệ thống đang sử dụng',
      en: 'Free monitoring for 1 domain, 1 IP address, and 1 keyword.',
    }
  },
  {
    icon: 'assets/images/free_tools/free_tool_3.png',
    desc: {
      vi: 'Miễn phí cảnh báo lỗ hổng bảo mật, phần mềm độc hại và tấn công APT, tài khoản lộ lọt',
      en: 'Free alerts for security vulnerabilities, malware, APT attacks, and leaked accounts.',
    }
  },
  {
    icon: 'assets/images/free_tools/free_tool_4.png',
    desc: {
      vi: 'Miễn phí kiểm tra dữ liệu bị rò rỉ',
      en: 'Free leak data checks.',
    }
  },
  {
    icon: 'assets/images/free_tools/free_tool_5.png',
    desc: {
      vi: 'Miễn phí xem báo cáo an ninh mạng',
      en: 'Free brand impersonation & phishing attack alerts.',
    }
  },
  {
    icon: 'assets/images/free_tools/free_tool_6.png',
    desc: {
      vi: 'Không cần đội ngũ IT chuyên sâu – Dễ dàng kiểm tra bảo mật chỉ với vài cú click!',
      en: 'No need for a dedicated IT team – easily check security with just a few clicks!',
    }
  },
];

export const PORTFOLIO = [
  {
    label: '<p class="fs-12 mb-1">Network<br/>Detection<br/>& Response</p>',
    icon: '/assets/images/portfolio/item1.svg',
  },
  {
    label: '<p class="fs-12 mb-1">Endpoint<br/>Detection<br/>& Response</p>',
    icon: '/assets/images/portfolio/item2.svg',
  },
  {
    label: '<p class="fs-12 mb-1">Endpoint<br/>Protection<br/>Platform</p>',
    icon: '/assets/images/portfolio/item3.svg',
  },
  {
    label: '<p class="fs-12 mb-1">Insider<br/>Threat</p>',
    icon: '/assets/images/portfolio/item4.svg',
  },
  {
    label: '<p class="fs-12 mb-1">Cloud<br/>WAAP</p>',
    icon: '/assets/images/portfolio/item5.svg',
  },
  {
    label: '<p class="fs-12 mb-1">Anti DDOS<br/>Volume Based</p>',
    icon: '/assets/images/portfolio/item6.svg',
  },
  {
    label: '<p class="fs-12 mb-1">3<sup>rd</sup> party<br/>Integration</p>',
    icon: '/assets/images/portfolio/item7.svg',
  },
];

export const PRIVILEGE = [
  'Khả năng giám sát tài sản số',
  'Khả năng giám sát tài sản CNTT',
  'Số lượt mở khóa cảnh báo	',
  'Cảnh báo lỗ hổng bảo mật',
  'Cảnh báo mã độc, phần mềm độc hại',
  'Cảnh báo tấn công có chủ đích vào doanh nghiệp',
  'Cảnh báo rò rỉ dữ liệu',
  'Cảnh báo tài khoản lộ lọt',
  'Cảnh báo hệ thống nhiễm mã độc',
  'Cảnh báo cổng mạng mở bất thường ',
  'Báo cáo nhanh, báo cáo phân tích chuyên sâu về Threat Intelligence',
  'Quản lý bề mặt tấn công tài sản số của tổ chức',
  'Phù hợp cho',
];

export const ADVANCE_PRIVILEGE = [
  'THREAT_FEED',
  'VULNERABILITY_ALERT',
  'MALWARE_ALERT',
  'APT_ALERT',
  'LEAKED_DATA_ALERT',
  'COMPROMISED_CREDENTIALS_ALERT',
  'COMPROMISED_CREDIT_CARDS_ALERT',
  'BRAND_ABUSE_ALERT',
  'UNMANAGED_ASSET_ALERT',
  'EXTERNAL_SURFACE_VULNERABILITY_ALERT',
  'EXTERNAL_SURFACE_ANOMALY_ALERT',
  'ALERT_MANAGEMENT',
  'THREAT_DATABASE',
  'INDEPTH_ANALYSIS_REPORT_1',
  'INDEPTH_ANALYSIS_REPORT_2',
  'THREAT_RESPONSE',
  'CUSTOMER_SUPPORT',
];

export const PACKAGES = [
  {
    title: 'THREATGUARD_FREE',
    description: 'TI_FREE_DESC',
    privilege: [
      { activate: 0, desc: '<span>1 tên miền, 1 địa chỉ IP, 1 từ khóa</span>' },
      { activate: 0, desc: '<span>10 phần mềm & hệ thống CNTT</span>' },
      { activate: 0 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 0 },
      {
        activate: 0,
        desc: `
        <span>Doanh nghiệp Startup, doanh nghiệp nhỏ mới phát triển, ít tài sản số, hệ thống đơn giản</span>
      `,
      },
    ],
  },
  {
    title: 'THREATGUARD_BASIC',
    description: 'TI_8_DESC',
    privilege: [
      { activate: 0, desc: '<span>3 tên miền, 3 địa chỉ IP, 3 từ khóa</span>' },
      { activate: 0, desc: '<span>20 phần mềm & hệ thống CNTT</span>' },
      { activate: 0, desc: '<span>5 lượt/tháng</span>' },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 0 },
      {
        activate: 0,
        desc: `
        <p class="mb-0">Doanh nghiệp vừa sở hữu từ 2 kênh online, đã có hệ thống CNTT nội bộ và cần giám sát chuyên sâu hơn, nhưng vẫn cần tiết kiệm chi phí.<br/>
        Đặc điểm hệ thống:
        </p>
        <p class="mb-0">2-3 website (trang chính + landing page + subdomain hoặc web dịch vụ khách hàng).</p>
        <p class="mb-0">Có máy chủ riêng hoặc cloud VPS, quản lý nhiều dịch vụ nội bộ.</p>
        <p class="mb-0">Sử dụng CRM, ERP, SaaS nội bộ, quản lý khách hàng, đơn hàng, tài chính.</p>
        <p class="mb-0">Có email server riêng thay vì dùng Gmail miễn phí.</p>
        <p class="mb-0">Bắt đầu lo ngại về bảo mật vì dữ liệu khách hàng, hệ thống tài chính quan trọng hơn.</p>
      `,
      },
    ],
  },
  {
    title: 'THREATGUARD_PLUS',
    description: 'TI_12_DESC',
    privilege: [
      { activate: 0, desc: '<span>5 tên miền, 5 địa chỉ IP, 5 từ khóa</span>' },
      { activate: 0, desc: '<span>30 phần mềm & hệ thống CNTT</span>' },
      { activate: 0, desc: '<span>10 lượt/tháng</span>' },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      {
        activate: 0,
        desc: `
      <p class="mb-0">Doanh nghiệp tầm trung, có nhiều hệ thống & hoạt động mạnh trên môi trường số & cần bảo vệ toàn diện.<br/>
      Đặc điểm hệ thống:
      </p>
      <p class="mb-0">3-5 website (trang chính, subdomain, portal cho khách hàng, API kết nối đối tác).</p>
      <p class="mb-0">Hệ thống đa nền tảng (Website + Mobile App + API), kết nối nhiều dịch vụ.</p>
      <p class="mb-0">Sử dụng nhiều server/VPN/Firewall để bảo vệ dữ liệu quan trọng.</p>
      <p class="mb-0">Có nhiều hệ thống nội bộ như CRM, ERP, SaaS doanh nghiệp, email server nội bộ</p>
      <p class="mb-0">Dữ liệu khách hàng, giao dịch tài chính, hoặc thông tin quan trọng cần giám sát chặt chẽ.</p>
      <p class="mb-0">Muốn chủ động phòng chống tấn công mạng thay vì chỉ phản ứng khi có sự cố.</p>
      `,
      },
    ],
  },
];

export const ADVANCE_PACKAGES = [
  {
    title: 'PROFESSIONAL',
    description: '',
    privilege: [
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
    ],
  },
  {
    title: 'DIGITAL_PROTECTION',
    description: '',
    privilege: [
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
    ],
  },
  {
    title: 'EASM',
    description: '',
    privilege: [
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 0 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
    ],
  },
  {
    title: 'EXTREME',
    privilege: [
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
      { activate: 1 },
    ],
  },
];

const features = [
  {
    en: 'Vulnerability Alert',
    vi: 'Cảnh báo lỗ hổng bảo mật ',
    tooltip: {
      en: `
      <p class="mb-0">A security vulnerability is a weakness in an information system, security processes, internal controls, or implementation that can be exploited by malicious actors. Newly discovered vulnerabilities can affect an organization's IT assets, and attackers may exploit them to gain unauthorized access.
      Viettel Threat Intelligence vulnerability alerts include:</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Newly discovered security vulnerabilities affecting the organization's asset set</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Specific methods for prevention, detection, and threat hunting</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Attack detection rules (if available)</p>
      `,
      vi: `
      <p class="mb-0">Lỗ hổng bảo mật là điểm yếu trong hệ thống thông tin, quy trình bảo mật hệ thống, kiểm soát nội bộ hoặc công tác triển khai có thể bị khai thác bởi tác nhân gây hại. Lỗ hổng mới xuất hiện có thể ảnh hưởng đến các tài sản CNTT của tổ chức, kẻ tấn công có thể lợi dụng lỗ hổng để xâm nhập đến tổ chức.
      Cảnh báo lỗ hổng bảo mật của Viettel Threat Intelligence bao gồm:</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Lỗ hổng bảo mật mới xuất hiện có ảnh hưởng tới tập tài sản của tổ chức</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Cách thức cụ thể để phòng chống, phát hiện, săn tìm tấn công</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các luật nhận diện tấn công (nếu có)</p>
`,
    },
  },
  {
    en: 'Malware Alert',
    vi: 'Cảnh báo mã độc, phần mềm độc hại',
    tooltip: {
      en: `
        <p class="mb-0">Malware (short for malicious software) refers to any software specifically designed to harm, exploit, or infiltrate computer systems, networks, or devices without user knowledge or consent.
        Examples: Virus, Worm, Trojan, Ransomware, Spyware, Adware, Botnet, Rootkit
        Viettel Threat Intelligence malware alerts include:</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Active malware strains affecting Vietnam</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Specific methods for prevention, detection, and threat hunting</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Specific indicators of compromise (IoCs)</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Attack detection rules (if available)</p>
      `,
      vi: `
      <p class="mb-0">Phần mềm độc hại, viết tắt của phần mềm độc hại, đề cập đến bất kỳ phần mềm nào được thiết kế đặc biệt để gây hại, khai thác hoặc xâm nhập vào hệ thống, mạng hoặc thiết bị máy tính mà người dùng không biết hoặc không đồng ý. VD: Virus, Worm, Trojan, Ransomware, Spyware, Adware, Botnet, Rootkit.
      Cảnh báo mã độc, phần mềm độc hại bao gồm:</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các dòng mã độc đang hoạt động có ảnh hưởng tại Việt Nam</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Cách thức cụ thể để phòng chống, phát hiện, săn tìm tấn công</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các dấu hiệu nhận biết xâm nhập cụ thể</p>
      <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các luật nhận diện tấn công (nếu có)</p>
      `,
    },
  },
  {
    en: 'APT Alert',
    vi: 'Cảnh báo tấn công có chủ đích',
    tooltip: {
      en: `
        <p class="mb-0">An Advanced Persistent Threat (APT) is a highly sophisticated and targeted cyberattack involving prolonged, stealthy intrusion into an organization's systems. Key attacker objectives: Espionage, Data theft, Sabotage, Financial fraud.
        Viettel Threat Intelligence APT alerts include:</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Activities and attack campaigns of APT groups operating in Vietnam</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Specific methods for prevention, detection, and threat hunting</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Specific indicators of compromise (IoCs)</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Attack detection rules (if available)</p>
      `,
      vi: `
        <p class="mb-0">Tấn công có chủ đích là một cuộc tấn công mạng tinh vi và có chủ đích, liên quan đến việc xâm nhập lén lút và kéo dài vào trong tổ chức. Mục tiêu chính của kẻ tấn công có thể bao gồm hoạt động gián điệp, đánh cắp dữ liệu, phá hoại hoặc các hoạt động độc hại khác (tài chính)
        Cảnh báo tấn công có chủ đích bao gồm:</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các hoạt động, chiến dịch tấn công của các nhóm APT đang hoạt động tại Việt Nam</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Cách thức cụ thể để phòng chống, phát hiện, săn tìm tấn công</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các dấu hiệu nhận biết xâm nhập cụ thể</p>
        <p class="mb-0"><img src="/assets/icons/check.svg" alt="" /> Các luật nhận diện tấn công (nếu có)</p>
      `,
    },
  },
  {
    en: 'Data Breach',
    vi: 'Cảnh báo rò rỉ dữ liệu',
    tooltip: {
      en: `
      <p class="mb-0">Data breach alerts may involve various types of data, including personal information, financial data, intellectual property, or any other information that an organization or individual is obligated to protect.</p>
      `,
      vi: `
      <p class="mb-0">Cảnh báo rò rỉ dữ liệu có thể liên quan đến nhiều loại dữ liệu khác nhau, bao gồm thông tin cá nhân, dữ liệu tài chính, sở hữu trí tuệ hoặc bất kỳ thông tin nào khác mà tổ chức hoặc cá nhân có nghĩa vụ bảo vệ.</p>
      `,
    },
  },
  {
    en: 'Compromised Credentials Alert',
    vi: 'Cảnh báo lộ lọt tài khoản ',
    tooltip: {
      en: `
      <p class="mb-0">Issues related to account data, passwords, and leaked login credentials being exposed and shared on the internet. Unauthorized individuals may gain access to usernames and passwords through illicit means and use these credentials to access an organization's systems or accounts.</p>
      `,
      vi: `
      <p class="mb-0">Các vấn đề liên quan đến dữ liệu tài khoản, mật khẩu, thông tin đăng nhập bị lộ lọt và chia sẻ trên không gian mạng. Các cá nhân trái phép có quyền truy cập vào tên người dùng và mật khẩu, có thể thông qua các phương tiện trái phép và sử dụng các thông tin xác thực này để truy cập hệ thống, tài khoản của tổ chức.</p>
      `,
    },
  },
  {
    en: 'Compromised System',
    vi: 'Cảnh báo hệ thống nhiễm mã độc',
    tooltip: {
      en: `
      <p class="mb-0">Issues related to infrastructure connecting to malware, affected assets, and connection timestamps.</p>
      `,
      vi: `
      <p class="mb-0">Các vấn đề liên quan đến cơ sở hạ tầng đang có kết nối đến mã độc, tài sản ảnh hưởng, thời gian kết nối.</p>
      `,
    },
  },
  {
    en: 'Anomaly Open Port',
    vi: 'Cảnh báo port mở bất thường',
    tooltip: {
      en: `
      <p class="mb-0">Unusual open ports on the organization's public IP addresses</p>
      `,
      vi: `
      <p class="mb-0">Các cổng mở bất thường trên các địa chỉ IP công cộng của tổ chức</p>
      `,
    },
  },
  {
    en: 'Quick report, in-depth analysis report on Threat Intelligence',
    vi: 'Báo cáo nhanh, báo cáo phân tích chuyên sâu về Threat Intelligence',
    tooltip: {
      en: `
      <p class="mb-0">Provides quick reports, in-depth analysis reports, and cybersecurity threat trend assessments.</p>
      `,
      vi: `
      <p class="mb-0">Cung cấp báo cáo nhanh, báo cáo phân tích chuyên sâu, nhận định xu thế nguy cơ an toàn thông tin.</p>
      `,
    },
  },
];

export const packagesCTI = [
  {
    id: 'extreme_threat_guard_free',
    plan: 'free',
    title: 'THREATGUARD_FREE',
    description: 'THREATGUARD_FREE_DESC',
    price: 0,
    mainFeatures: [
      {
        active: true,
        en: `
        <span>Digital Asset Monitoring:</span><br/>
        <span>1 domain, 1 IP address, 1 keyword</span>
        `,
        vi: `
        <span>Giám sát tài sản số:</span><br/>
        <span>1 tên miền, 1 địa chỉ IP, 1 từ khóa</span>
        `,
      },
      {
        active: true,
        en: `
        <span>IT Asset Monitorin:</span><br/>
        <span>10 Software & Systems in Use</span>
        `,
        vi: `
        <span>Giám sát tài sản CNTT:</span><br/>
        <span>10 Phần mềm & hệ thống đang sử dụng</span>
        `,
      },
      {
        en: `
        <span>Alert unlocks:</span><br/>
        <span>Not supported</span>
        `,
        vi: `
        <span>Lượt mở khóa cảnh báo:</span><br/>
        <span>Không hỗ trợ</span>
        `,
      },
    ],
    privilege: [
      {
        title: 'BASIC_TITLE',
        features,
      },
    ],
  },
  {
    id: 'extreme_threat_guard_basic',
    plan: 'basic',
    title: 'THREATGUARD_BASIC',
    description: 'THREATGUARD_BASIC_DESC',
    sale: 25,
    price: 5000000,
    priceYearly: 60000000,
    mainFeatures: [
      {
        active: true,
        en: `
        <span>Digital Asset Monitoring:</span><br/>
        <span>3 domains, 3 IP addresses, 3 keywords</span>
        `,
        vi: `
        <span>Giám sát tài sản số:</span><br/>
        <span>3 tên miền, 3 địa chỉ IP, 3 từ khóa</span>
        `,
      },
      {
        active: true,
        en: `
        <span>IT Asset Monitorin:</span><br/>
        <span>20 Software & Systems in Use</span>
        `,
        vi: `
        <span>Giám sát tài sản CNTT:</span><br/>
        <span>20 Phần mềm & hệ thống đang sử dụng</span>
        `,
      },
      {
        active: true,
        en: `
        <span>Alert unlock:</span><br/>
        <span>5 unlocks/month</span>
        `,
        vi: `
        <span>Lượt mở khóa cảnh báo:</span><br/>
        <span>5 lượt/tháng</span>
        `,
      },
    ],
    privilege: [
      {
        title: 'BASIC_TITLE',
        features,
      },
    ],
  },
  {
    id: 'extreme_threat_guard_plus',
    plan: 'plus',
    title: 'THREATGUARD_PLUS',
    description: 'THREATGUARD_PLUS_DESC',
    sale: 25,
    price: 12000000,
    priceYearly: 144000000,
    badge: {
      desc: 'THREATGUARD_PLUS_BADGE',
      icon: '/assets/icons/rating-star-bubble-2.svg',
      background: '#ee0033',
      color: '#ffffff',
    },
    mainFeatures: [
      {
        active: true,
        en: `
        <span>Digital Asset Monitoring:</span><br/>
        <span>5 domains, 5 IP addresses, 5 keywords</span>
        `,
        vi: `
        <span>Giám sát tài sản số:</span><br/>
        <span>5 tên miền, 5 địa chỉ IP, 5 từ khóa</span>
        `,
      },
      {
        active: true,
        en: `
        <span>IT Asset Monitorin:</span><br/>
        <span>30 Software & Systems in Use</span>
        `,
        vi: `
        <span>Giám sát tài sản CNTT:</span><br/>
        <span>30 Phần mềm & hệ thống đang sử dụng</span>
        `,
      },
      {
        active: true,
        en: `
        <span>Alert unlock:</span><br/>
        <span>10 unlocks/month</span>
        `,
        vi: `
        <span>Lượt mở khóa cảnh báo:</span><br/>
        <span>10 lượt/tháng</span>
        `,
      },
    ],
    privilege: [
      {
        title: 'PLUS_TITLE_1',
        features: [
          ...features,
          {
            en: 'External Attack Surface Management',
            vi: 'Quản lý bề mặt tấn công tài sản số của tổ chức',
            tooltip: {
              en: 'The system continuously monitors and detects potential risks from an organization’s public digital assets across cyberspace, especially unknown assets. This helps organizations optimize resources for handling, assessing, and mitigating security threats early and effectively',
              vi: 'Hệ thống giám sát liên tục và phát hiện sớm các nguy cơ từ các tài sản số công khai của tổ chức trên không gian mạng, đặc biệt là các tài sản chưa được biết đến, qua đó hỗ trợ tổ chức tối ưu nguồn lực xử lý, đánh giá, ngăn chặn sớm và chính xác các nguy cơ bảo mật tiềm tàng một cách hiệu quả',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'extreme_threat_check',
    plan: 'premium',
    title: 'THREAT_CHECK_PREMIUM',
    description: 'THREAT_CHECK_PREMIUM_DESC',
    sale: 50,
    price: 25000000,
    priceYearly: 25000000,
    mainFeatures: [],
    privilege: [
      {
        title: 'THREAT_CHECK_TITLE',
        features: [
          {
            en: 'Data Breach',
            vi: 'Cảnh báo rò rỉ dữ liệu',
            tooltip: {
              en: `
              <p class="mb-0">Data breach alerts may involve various types of data, including personal information, financial data, intellectual property, or any other information that an organization or individual is obligated to protect.</p>
              `,
              vi: `
              <p class="mb-0">Cảnh báo rò rỉ dữ liệu có thể liên quan đến nhiều loại dữ liệu khác nhau, bao gồm thông tin cá nhân, dữ liệu tài chính, sở hữu trí tuệ hoặc bất kỳ thông tin nào khác mà tổ chức hoặc cá nhân có nghĩa vụ bảo vệ.</p>
              `,
            },
          },
          {
            en: 'Compromised Credentials Alert',
            vi: 'Cảnh báo lộ lọt tài khoản ',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to account data, passwords, and leaked login credentials being exposed and shared on the internet. Unauthorized individuals may gain access to usernames and passwords through illicit means and use these credentials to access an organization's systems or accounts.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan đến dữ liệu tài khoản, mật khẩu, thông tin đăng nhập bị lộ lọt và chia sẻ trên không gian mạng. Các cá nhân trái phép có quyền truy cập vào tên người dùng và mật khẩu, có thể thông qua các phương tiện trái phép và sử dụng các thông tin xác thực này để truy cập hệ thống, tài khoản của tổ chức.</p>
              `,
            },
          },
          {
            en: 'Brand Phishing',
            vi: 'Cảnh báo tấn công lừa đảo thương hiệu',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to the unauthorized use of an organization's name, logo, or brand identity for fraudulent activities. Domains may be registered with the intent of engaging in phishing scams, online fraud, or other malicious activities.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan đến sử dụng tên, logo, nhận diện thương hiệu của tổ chức để lừa đảo người dùng.Tên miền đã được đăng ký với mục đích lừa đảo hoặc tham gia vào các hoạt động độc hại, thường nhằm mục đích thực hiện lừa đảo trực tuyến, tấn công lừa đảo hoặc hành vi lừa đảo khác</p>
              `,
            },
          },
          {
            en: 'Brand Impersonate',
            vi: 'Cảnh báo tấn công giả mạo thương hiệu',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to the unauthorized use of an organization’s name and images for impersonation. Domains may be registered with the purpose of brand impersonation or participating in malicious activities, typically aimed at online fraud, phishing attacks, or other deceptive practices.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan sử dụng trái phép hình ảnh, tên để mạo danh thương hiệu của tổ chức.Tên miền đã được đăng ký với mục đích mạo danh thương hiệu hoặc tham gia vào các hoạt động độc hại, thường nhằm mục đích thực hiện lừa đảo trực tuyến, tấn công lừa đảo hoặc hành vi lừa đảo khác</p>
              `,
            },
          },
          {
            en: 'Compromised System',
            vi: 'Cảnh báo hệ thống nhiễm mã độc',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to infrastructure connecting to malware, affected assets, and connection timestamps.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan đến cơ sở hạ tầng đang có kết nối đến mã độc, tài sản ảnh hưởng, thời gian kết nối.</p>
              `,
            },
          },
          {
            en: 'Web Encryption',
            vi: 'Cảnh báo mã hóa web',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to certificates (old, expired) or the use of weak security protocols.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan đến chứng chỉ (cũ, hết hạn) hoặc việc sử dụng các giao thức bảo mật yếu.</p>
              `,
            },
          },
          {
            en: 'HTTP Header Security',
            vi: 'Cảnh báo bảo mật header HTTP',
            tooltip: {
              en: `
              <p class="mb-0">Issues related to missing fields in protocol headers.</p>
              `,
              vi: `
              <p class="mb-0">Các vấn đề liên quan đến thiếu các trường trong tiêu đề của các giao thức.</p>
              `,
            },
          },
          {
            en: 'Threat check report',
            vi: 'Báo cáo đánh giá mức độ rủi ro an ninh mạng cho doanh nghiệp',
            tooltip: {
              en: `
              <p class="mb-0">Threat Check Report offers a comprehensive overview of the security status of an organization's publicly exposed digital assets. This report helps businesses identify potential risks, evaluate their impact, and prioritize mitigation measures accordingly.</p>
              `,
              vi: `
              <p class="mb-0">Báo cáo đánh giá mức độ rủi ro an ninh mạng cung cấp cái nhìn tổng thể về tình trạng bảo mật của các tài sản số công khai của tổ chức. Báo cáo này giúp doanh nghiệp xác định các rủi ro tiềm ẩn, đánh giá mức độ ảnh hưởng và ưu tiên biện pháp xử lý.</p>
              `,
            },
          },
        ],
      },
    ],
  },
];

export const packagesATI = [
  {
    id: 'professional',
    plan: 'professional',
    title: 'PROFESSIONAL',
    description: 'PROFESSIONAL_DESC',
    price: 1,
    privilege: [
      'Nguồn dữ liệu mối đe dọa (Threat Feed)',
      'Cảnh báo lỗ hổng bảo mật (Vulnerability Alert)',
      'Cảnh báo mã độc, phần mềm độc hại (Malware Alert)',
      'Cảnh báo tấn công có chủ đích (Advanced Persistent Threatt)',
      'Quản lý các cảnh báo bảo mật (Alert Management)',
      'Cơ sở dữ liệu mối đe dọa (Threat Database)',
      'Báo cáo phân tích chuyên sâu (In-depth analysis Reports)',
      'Phản ứng với mối đe dọa (Threat Response)',
      'Hỗ trợ khách hàng 24/7',
    ],
  },
  {
    id: 'digital_protection',
    plan: 'digital_protection',
    title: 'DIGITAL_PROTECTION',
    description: 'DIGITAL_PROTECTION_DESC',
    price: 1,
    privilege: [
      'Cảnh báo rò rỉ dữ liệu (Data Breach)',
      'Cảnh báo tài khoản lộ lọt (Compromised Credential)',
      'Cảnh báo lộ lọt thẻ tín dụng (Compromised Credit Cards)',
      'Cảnh báo tấn công lừa đảo, giả mạo thương hiệu (Phishing/Brand-Abuse)',
      'Quản lý các cảnh báo bảo mật (Alert Management)',
      'Cơ sở dữ liệu mối đe dọa (Threat Database)',
      'Báo cáo phân tích chuyên sâu (In-depth analysis Reports)',
      'Phản ứng với mối đe dọa (Threat Response)',
      'Hỗ trợ khách hàng 24/7',
    ],
  },
  {
    id: 'easm',
    plan: 'easm',
    title: 'External Attack Surface Management',
    description: 'EASM_DESC',
    price: 1,
    privilege: [
      'Cảnh báo tài sản số không được quản lý (Unmanaged Assets Alert)',
      'Cảnh báo lỗ hổng bảo mật từ bên ngoài (External Surface Vulnerability Alert)',
      'Cảnh báo hoạt động bất thường từ bên ngoài (External Surface Anomaly Alert)',
      'Quản lý các cảnh báo bảo mật (Alert Management)',
      'Cơ sở dữ liệu mối đe dọa (Threat Database)',
      'Báo cáo phân tích chuyên sâu (In-depth analysis Reports)',
      'Phản ứng với mối đe dọa (Threat Response)',
      'Hỗ trợ khách hàng 24/7',
    ],
  },
  {
    id: 'extreme',
    plan: 'extreme',
    title: 'EXTREME',
    description: 'EXTREME_DESC',
    price: 1,
    badge: {
      desc: 'EXTREME_BADGE',
      icon: '/assets/icons/rating-star-bubble-2.svg',
      background: '#ee0033',
      color: '#ffffff',
    },
    privilege: [
      'Nguồn dữ liệu mối đe dọa (Threat Feed)',
      'Cảnh báo lỗ hổng bảo mật (Vulnerability Alert)',
      'Cảnh báo mã độc, phần mềm độc hại (Malware Alert)',
      'Cảnh báo tấn công có chủ đích (Advanced Persistent Threat)',
      'Cảnh báo rò rỉ dữ liệu (Data Breach)',
      'Cảnh báo tài khoản lộ lọt (Compromised Credential)',
      'Cảnh báo lộ lọt thẻ tín dụng (Compromised Credit Cards)',
      'Cảnh báo tài sản số không được quản lý (Unmanaged Assets Alert)',
      'Cảnh báo tài sản số không được quản lý (Unmanaged Assets Alert)',
      'Cảnh báo lỗ hổng bảo mật từ bên ngoài (External Surface Vulnerability Alert)',
      'Cảnh báo hoạt động bất thường từ bên ngoài (External Surface Anomaly Alert)',
      'Quản lý các cảnh báo bảo mật (Alert Management)',
      'Cơ sở dữ liệu mối đe dọa (Threat Database)',
      'Báo cáo phân tích chuyên sâu (In-depth analysis Reports)',
      'Phản ứng với mối đe dọa (Threat Response)',
      'Hỗ trợ khách hàng 24/7',
    ],
  },
];
