import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ThreatCheckService } from '@ti/services/threat-check.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { ToastrService } from 'ngx-toastr';
import { finalize, switchMap } from 'rxjs/operators';

interface Field {
  label: string;
  type: string;
  key: string;
  placeholder?: string;
  required?: boolean;
  pattern?: string | RegExp;
  options?: {
    label: string;
    value: string;
  }[];
}
@Component({
  selector: 'ti-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {
  @Input() pack: any;
  @Input() active = true;
  formReport: FormGroup;
  lang = 'vi';
  listFields: Field[] = [
    {
      label: 'NAME',
      key: 'name',
      type: 'text',
      placeholder: 'NAME_PLACEHOLDER',
      required: true,
    },
    {
      label: 'EMAIL',
      type: 'text',
      key: 'email',
      placeholder: 'EMAIL_PLACEHOLDER',
      required: true,
    },
    {
      label: 'COMPANY_NAME',
      key: 'company_name',
      type: 'text',
      placeholder: 'COMPANY_NAME_PLACEHOLDER',
      required: false,
    },
    {
      label: 'PHONE',
      key: 'phone_number',
      type: 'text',
      placeholder: 'PHONE_PLACEHOLDER',
      required: false,
    },
    {
      label: 'HOW_TO_HELP',
      type: 'textarea',
      key: 'description',
      placeholder: 'NOTE_PLACEHOLDER',
    },
  ];
  submitted = false;
  imagePath: any;
  captchaBase64: string;
  sending = false;
  invalidCaptcha = false;
  invalidLink = false;
  notExistLink = false;
  onChange: (data) => void;

  noWhitespaceValidator(control: FormControl): any {
    const isWhitespace = (control.value?.replace(/<(?!img)[^>]+>/gi, '').trim() || '').trim()?.length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  validateCaptcha(control: FormControl): any {
    return control.value.indexOf(' ') < 0;
  }

  validateLink(control: FormControl) {
    const TI_REGEX_URL: RegExp =
      /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    const TI_REGEX_DOMAIN: RegExp = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    const TI_REGEX_IP: RegExp =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?=\s*netmask)$/;
    const TI_REGEX_URL_2 =
      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/;

    return (
      // url
      ((control?.value?.startsWith('http') ||
        control?.value?.startsWith('tcp') ||
        control?.value?.startsWith('udp') ||
        control?.value?.startsWith('ftp')) &&
        TI_REGEX_URL.test(control.value)) ||
        // domain
        TI_REGEX_DOMAIN.test(control.value) ||
        // url //
        TI_REGEX_URL_2.test(control.value) ||
        // ip
        TI_REGEX_IP.test(control.value)
        ? null
        : {
            validateInput: {
              valid: false,
            },
          }
    );
  }

  constructor(
    private modalRef: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private threatCheckService: ThreatCheckService,
  ) {}

  ngOnInit(): void {
    this.formReport = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.email, Validators.required, this.noWhitespaceValidator]),
      phone_number: new FormControl(''),
      company_name: new FormControl(''),
      description: new FormControl(''),
    });
    this.lang = localStorage.getItem('lang') ?? 'vi';
  }

  ngOnDestroy(): void {}

  onCloseModal() {
    this.modalRef.close();
  }

  handleClose() {
    this.onCloseModal();
  }

  handleSendReport() {
    this.submitted = true;
    this.invalidCaptcha = false;
    this.invalidLink = false;
    this.notExistLink = false;
    if (this.formReport.valid) {
      this.sending = true;
      this.recaptchaV3Service
        .execute('importantAction')
        .pipe(
          finalize(() => {
            this.sending = false;
          }),
        )
        .pipe(
          switchMap((recaptcha: string) => {
            const body = {
              ...this.formReport.value,
              action: 'upgrade',
              plan_code: this.pack.plan,
              lang: this.lang,
              package: this.active ? 12 : 1,
              recaptcha,
            };
            return this.threatCheckService.submit(body);
          }),
        )
        .subscribe(
          (res: any) => {
            this.handleClose();
            const message = this.translate.instant('CHECK_EMAIL_TO_RECEIVE_RESULT');
            this.toastrService.success(message, '');
          },
          error => {
            if (error.error.detail === 'Invalid captcha') {
              this.invalidCaptcha = true;
            } else if (error.error.detail?.[0]?.msg === 'Link error') {
              this.invalidLink = true;
            } else if (error.error.detail === 'LINK_NOT_EXIST') {
              this.notExistLink = true;
            } else {
              const message = this.lang === 'en' ? 'Contact sent failed' : 'Gửi liên hệ thất bại';
              this.toastrService.error(message, '');
              this.handleClose();
            }
          },
        );
    }
  }

  registerFormControl(key: string) {
    return this.formReport.controls[key];
  }

  handleChangeInputCaptcha(event): void {
    this.invalidCaptcha = false;
  }

  handleChangeInputLink(event, key): void {
    if (key === 'link') {
      this.notExistLink = false;
    }
  }

  checkValidField(key): boolean {
    if (this.formReport.controls[key]) {
      return this.formReport.controls[key].status === 'VALID';
    }
    return false;
  }
}
