import { Component, OnInit } from '@angular/core';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'ti-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss'],
})
export class RecaptchaComponent implements OnInit {
  // constructor(private recaptchaV3Service: ReCaptchaV3Service) {}

  ngOnInit(): void {}

  // public executeImportantAction(): void {
  //   this.recaptchaV3Service.execute('importantAction').subscribe(token => this.handleToken(token));
  // }

  // handleToken(token: string): void {
  //   console.log({ token });
  // }
}
