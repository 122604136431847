import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { BehaviorSubject, Subject, animationFrameScheduler, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, endWith, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';

const easeOutQuad = (x: number): number => x * (2 - x);

@Directive({
  selector: '[tiCountUp]',
})
export class CountUpDirective implements OnInit, OnDestroy {
  @Input() hasAlphabet = false;
  @Input() suffix = '+';
  private readonly count$ = new BehaviorSubject(0);
  private readonly duration$ = new BehaviorSubject(3000);
  private destroy$: Subject<void> = new Subject<void>();

  private readonly currentCount$ = combineLatest([this.count$, this.duration$]).pipe(
    switchMap(([count, duration]) => {
      const startTime = animationFrameScheduler.now();
      return interval(0, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map(elapsedTime => elapsedTime / duration),
        takeWhile(progress => progress <= 1),
        map(easeOutQuad),
        map(progress => Math.round(progress * count)),
        endWith(count),
        distinctUntilChanged()
      );
    })
  );

  @Input('tiCountUp')
  set count(count: number) {
    this.count$.next(count);
  }

  @Input()
  set duration(duration: number) {
    this.duration$.next(duration);
  }

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.displayCurrentCount();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private displayCurrentCount(): void {
    this.currentCount$.pipe(takeUntil(this.destroy$)).subscribe(currentCount => {
      const formattedCount = currentCount.toLocaleString('en-US');
      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', `${formattedCount}${this.suffix}`);
    });
  }
}
