import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ti-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent implements OnInit {
  constructor(private modalRef: NgbActiveModal) {}

  ngOnInit() {}

  handleClose() {
    this.modalRef.close();
  }
}
