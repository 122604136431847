import {NgModule} from '@angular/core';
import {TiIconComponent} from './ti-icon.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [TiIconComponent],
  imports: [CommonModule],
  exports: [TiIconComponent]
})
export class TiIconModule {
}
