import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { AppEnvironmentService } from '@ti/app.environment.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  protected captchaAPI = this.env.report + '/form';
  protected createReportAPI = this.env.report + '/create';

  constructor(private http: HttpClient, private env: AppEnvironmentService) {
  }

  getCaptchaBase64() {
    return this.http.get(this.captchaAPI)
  }

  sendReport(body) {
    return this.http.post(this.createReportAPI, body)
  }
}
