import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MixpanelService } from '@ti/mixpanel.service';

@Component({
  selector: 'ti-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  constructor(public translate: TranslateService, private mixpanelService: MixpanelService) { }

  ngOnInit(): void {
  }

  mixpanelTrack() {
    this.mixpanelService.track('about-vcs-click');
  }

}
