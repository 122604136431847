import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppEnvironmentService } from '@ti/app.environment.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';

@Component({
  selector: 'ti-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  saleEmail = '';
  techEmail = '';
  hotline = '';
  yearNumber = new Date().getFullYear();

  constructor(private env: AppEnvironmentService, private modalService: NgbModal) {
    this.hotline = this.env.hotline;
    this.saleEmail = this.env.saleEmail;
    this.techEmail = this.env.techEmail;
  }

  ngOnInit(): void {}

  linkTo(link: string) {
    window.open(link, '_blank');
  }

  contactUs(): void {
    this.modalService.open(ContactModalComponent);
  }
}
