import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { CoreModule } from '@ti/core/core.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

import { TiScrollToService } from '@ti/ti-scroll-to.service';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MixpanelService } from './mixpanel.service';
@NgModule({
  declarations: [AppComponent, PageNotFoundComponent],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    InfiniteScrollModule,
    DeviceDetectorModule,
    ToastrModule.forRoot(),
    ScrollToModule.forRoot(),
    RecaptchaV3Module,
  ],
  providers: [
    MixpanelService,
    TiScrollToService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
