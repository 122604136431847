export const SCORE = {
  CRITICAL: 'CRITICAL',
  MEDIUM: 'MEDIUM',
  HIGH: 'HIGH',
  LOW: 'LOW',
  GOOD: 'GOOD',
};

export const RANK_SCORE = {
  CRITICAL: 'F',
  MEDIUM: 'D',
  HIGH: 'C',
  LOW: 'B',
  GOOD: 'A',
};

export const FETCH_KEYS = [
  'asset',
  'credential',
  'databreach',
  'phishing',
  'impersonate',
  'compromised-system',
  'port-anomaly',
  'web-encryption',
  'http-header-security',
];

export const CREDENTIAL_LEAK_COLUMNS = [
  {
    field: 'created',
    label: 'TABLE_COLUMN_CREATED',
  },
  {
    field: 'url',
    label: 'TABLE_COLUMN_URL',
  },
  {
    field: 'username',
    label: 'TABLE_COLUMN_USERNAME',
  },
  {
    field: 'password',
    label: 'TABLE_COLUMN_PASSWORD',
  },
  {
    field: 'source',
    label: 'TABLE_COLUMN_SOURCE',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  {
    field: 'asset_severity',
    label: 'TABLE_COLUMN_ASSET_SEVERITY',
  },
];

export const HTTP_HEADER_SECURITY_COLUMNS = [
  {
    field: 'domain',
    label: 'TABLE_COLUMN_DOMAIN',
  },
  {
    field: 'issues',
    label: 'TABLE_COLUMN_ISSUES',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  {
    field: 'asset_severity',
    label: 'TABLE_COLUMN_ASSET_SEVERITY',
  },
];

export const PORT_ANOMALY_COLUMNS = [
  {
    field: 'created',
    label: 'TABLE_COLUMN_CREATED',
  },
  {
    field: 'ipaddress',
    label: 'TABLE_COLUMN_IPADDRESS',
  },
  {
    field: 'port',
    label: 'TABLE_COLUMN_PORT',
  },
  {
    field: 'services',
    label: 'TABLE_COLUMN_SERVICES',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  {
    field: 'asset_severity',
    label: 'TABLE_COLUMN_ASSET_SEVERITY',
  },
];

export const COMPROMISED_COLUMNS = [
  {
    field: 'created',
    label: 'TABLE_COLUMN_CREATED',
  },
  {
    field: 'connected',
    label: 'TABLE_COLUMN_CONNECTED',
  },
  {
    field: 'ipaddress',
    label: 'TABLE_COLUMN_IPADDRESS',
  },
  {
    field: 'ioc',
    label: 'TABLE_COLUMN_IOC',
  },
  {
    field: 'malware',
    label: 'TABLE_COLUMN_MALWARE',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  {
    field: 'asset_severity',
    label: 'TABLE_COLUMN_ASSET_SEVERITY',
  },
];

export const IMPERSONATE_AND_PHISHING_COLUMNS = [
  {
    field: 'created',
    label: 'TABLE_COLUMN_CREATED',
  },
  {
    field: 'domain',
    label: 'TABLE_COLUMN_DOMAIN',
  },
  {
    field: 'keyword',
    label: 'TABLE_COLUMN_KEYWORD',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  // {
  //   field: 'asset_severity',
  //   label: 'TABLE_COLUMN_ASSET_SEVERITY',
  // },
];

export const DATA_BREACH_COLUMNS = [
  {
    field: 'created',
    label: 'TABLE_COLUMN_CREATED',
  },
  {
    field: 'title',
    label: 'TABLE_COLUMN_TITLE',
  },
  {
    field: 'link',
    label: 'TABLE_COLUMN_LINK',
  },
  {
    field: 'source',
    label: 'TABLE_COLUMN_SOURCE',
  },
  {
    field: 'total',
    label: 'TABLE_COLUMN_TOTAL',
  },
  {
    field: 'alert_severity',
    label: 'TABLE_COLUMN_ALERT_SEVERITY',
  },
  // {
  //   field: 'asset_severity',
  //   label: 'TABLE_COLUMN_ASSET_SEVERITY',
  // },
];

export const SOLUTIONS = [
  {
    id: 'credential',
    title: 'credentialLeak',
    desc_vi:
      'Các vấn đề liên quan đến dữ liệu tài khoản, mật khẩu, thông tin đăng nhập bị lộ lọt và chia sẻ trên không gian mạng.',
    desc_en:
      'Issues related to account data, passwords, and login information being exposed and shared on the internet.',
    shortTerm: [
      {
        en: `Compromise Assessment: Evaluate the system's vulnerability to intrusion.`,
        vi: 'Compromise Assessment: Đánh giá khả năng hệ thống bị xâm nhập.',
      },
      {
        en: `Threat Intelligence: Monitor alerts for compromised account to activate prompt response and mitigation.`,
        vi: 'Threat Intelligence: Theo dõi cảnh báo về lộ lọt tài khoản để phản ứng sớm.',
      },
      {
        en: `PIM/PAM (Privileged Identity Management/Privileged Access Management): Mitigate privilege escalation attacks in case privileged accounts are compromised.`,
        vi: 'PIM/PAM (Privileged Identity Management/Privileged Access Management): Hạn chế tấn công leo thang nếu tài khoản đặc quyền bị lộ lọt.',
      },
    ],
    longTerm: [
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 giúp phát hiện sớm các hành vi tấn công.',
      },
      {
        en: 'Insider Threat: Assess internal threats within the organization.',
        vi: 'Insider Threat: Đánh giá rủi ro từ các mối nguy bên trong doanh nghiệp.',
      },
    ],
  },
  {
    id: 'databreach',
    title: 'dataBreach',
    desc_vi: 'Các vấn đề liên quan đến dữ liệu nhạy cảm, thông tin cá nhân bị lộ lọt rao bán trên không gian mạng.',
    desc_en: 'Issues related to sensitive data or personal information being leaked or sold on the internet.',
    shortTerm: [
      {
        en: `Compromise Assessment: Evaluate the system's vulnerability to intrusion.`,
        vi: 'Compromise Assessment: Đánh giá khả năng hệ thống bị xâm nhập.',
      },
      {
        en: 'Pentest: Identify and evaluate application vulnerabilities that could compromise data security and potentially lead to data breaches.',
        vi: 'Pentest: Phát hiện các lỗ hổng ứng dụng có thể gây ra lộ lọt dữ liệu.',
      },
      {
        en: 'Threat Intelligence: Monitor alerts for data breaches to activate prompt response and mitigation.',
        vi: 'Threat Intelligence: Theo dõi cảnh báo về lộ lọt tài khoản để phản ứng sớm.',
      },
    ],
    longTerm: [
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 giúp phát hiện sớm các hành vi tấn công.',
      },
      {
        en: 'Insider Threat: Detect internal data breach threats.',
        vi: 'Insider Threat: Phát hiện sớm các rủi ro lộ lọt dữ liệu từ nội bộ.',
      },
    ],
  },
  {
    id: 'phishing',
    title: 'phishing',
    desc_vi: 'Các vấn đề liên quan đến sử dụng tên, logo, nhận diện thương hiệu của tổ chức để lừa đảo người dùng.',
    desc_en: 'Issues related to the use of an organization’s name, logo, or brand identity to deceive users.',
    shortTerm: [
      {
        en: 'Threat Response: Take-down brand phishing domains.',
        vi: 'Threat Response: Gỡ bỏ các tên miền mạo danh thương hiệu.',
      },
    ],
    longTerm: [
      {
        en: 'Threat Intelligence: Monitor alerts for brand phishing to facilitate prompt response and mitigation.',
        vi: 'Threat Intelligence: Theo dõi cảnh báo về mạo danh thương hiệu để phản ứng sớm.',
      },
    ],
  },
  {
    id: 'impersonate',
    title: 'impersonate',
    desc_vi: 'Các vấn đề liên quan sử dụng trái phép hình ảnh, tên để mạo danh thương hiệu của tổ chức.',
    desc_en: 'Issues related to the unauthorized use of images or names to impersonate an organization’s brand.',
    shortTerm: [
      {
        en: 'Threat Response: Take-down brand impersonation domains.',
        vi: 'Threat Response: Gỡ bỏ các tên miền lừa đảo thương hiệu.',
      },
    ],
    longTerm: [
      {
        en: 'Threat Intelligence: Monitor alerts for brand impersonation to activate prompt response and mitigation.',
        vi: 'Threat Intelligence: Theo dõi cảnh báo về lừa đảo thương hiệu để phản ứng sớm.',
      },
    ],
  },
  {
    id: 'compromised-system',
    title: 'compromisedSystem',
    desc_vi: 'Các vấn đề liên quan đến cơ sở hạ tầng đang có kết nối đến mã độc, tài sản ảnh hưởng, thời gian kết nối.',
    desc_en: 'Issues related to infrastructure connected to malware, affected assets, and connection time.',
    shortTerm: [
      {
        en: `Compromise Assessment: Evaluate the system's vulnerability to intrusion.`,
        vi: 'Compromise Assessment: Đánh giá khả năng hệ thống bị xâm nhập.',
      },
      {
        en: 'Threat Intelligence: Monitor alerts for malware infections to activate prompt response and mitigation.',
        vi: 'Threat Intelligence: Theo dõi cảnh báo về lộ lọt tài khoản để phản ứng sớm.',
      },
      {
        en: 'Audit: Assess and inspect the system to enforce compliance with cybersecurity standards.',
        vi: 'Audit: Đánh giá, kiểm tra hệ thống đảm bảo tuân thủ theo các baseline ATTT.',
      },
    ],
    longTerm: [
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 phát hiện sớm hành vi tấn công.',
      },
    ],
  },
  {
    id: 'port-anomaly',
    title: 'openPortAnomaly',
    desc_vi:
      'Các vấn đề liên quan đến port không phổ biến, bất thường được mở, tăng nguy cơ bị tấn công và mất an toàn thông tin.',
    desc_en:
      'Issues related to uncommon or unusual ports being open, increasing the threat of attacks and security breaches.',
    shortTerm: [
      {
        en: `Compromise Assessment: Evaluate the system's vulnerability to intrusion.`,
        vi: 'Compromise Assessment: Đánh giá khả năng hệ thống bị xâm nhập.',
      },
      {
        en: 'Threat Intelligence: Monitor alerts for system threats facilitate prompt response and mitigation.',
        vi: 'Threat Intelligence: Theo dõi cảnh báo về lộ lọt tài khoản để phản ứng sớm.',
      },
      {
        en: 'External Attack Surface Management: Manage publicly exposed assets on the Internet.',
        vi: 'External Attack Surface Management: Quản lý các tài sản công khai trên internet.',
      },
      {
        en: 'PIM/PAM (Privileged Identity Management/Privileged Access Management): Limit privilege escalation attacks.',
        vi: 'PIM/PAM (Privileged Identity Management/Privileged Access Management): Hạn chế tấn công leo thang.',
      },
    ],
    longTerm: [
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 phát hiện sớm hành vi tấn công.',
      },
    ],
  },
  {
    id: 'web-encryption',
    title: 'webEncryption',
    desc_vi: 'Các vấn đề liên quan đến chứng chỉ (cũ, hết hạn) hoặc việc sử dụng các giao thức bảo mật yếu.',
    desc_en: 'Issues related to certificates (outdated or expired) or the use of weak security protocols.',
    shortTerm: [
      {
        en: 'Application Pentest: Identify application vulnerabilities.',
        vi: 'Application Pentest: Phát hiện các lỗ hổng ứng dụng',
      },
      {
        en: 'Audit: Assess and inspect the system to enforce compliance with cybersecurity standards.',
        vi: 'Audit: Đánh giá, kiểm tra hệ thống đảm bảo tuân thủ theo các baseline ATTT.',
      },
    ],
    longTerm: [
      {
        en: 'External Attack Surface Management: Manage publicly exposed assets on the Internet.',
        vi: 'External Attack Surface Management: Quản lý các tài sản công khai trên internet.',
      },
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 phát hiện sớm hành vi tấn công.',
      },
    ],
  },
  {
    id: 'http-header-security',
    title: 'httpHeaderSecurity',
    desc_vi: 'Các vấn đề liên quan đến thiếu các trường trong tiêu đề của các giao thức.',
    desc_en: 'Issues related to missing fields in the headers of web protocols.',
    shortTerm: [
      {
        en: 'Application Pentest: Identify application vulnerabilities.',
        vi: 'Application Pentest: Phát hiện các lỗ hổng ứng dụng',
      },
    ],
    longTerm: [
      {
        en: 'External Attack Surface Management: Manage publicly exposed assets on the Internet.',
        vi: 'External Attack Surface Management: Quản lý các tài sản công khai trên internet.',
      },
      {
        en: 'Security Operation Center: 24/7 cybersecurity monitoring to proactively identify and mitigate potential attack behaviors.',
        vi: 'Security Operation Center: Giám sát ATTT 24/7 phát hiện sớm hành vi tấn công.',
      },
    ],
  },
];

export const PROBLEMS = [
  {
    key: 'credential',
    name: 'CREDENTIAL_LEAK',
    icon: 'assets/icons/threat-check/credential-leak.svg',
  },
  {
    key: 'databreach',
    name: 'DATA_BREACH',
    icon: 'assets/icons/threat-check/databreach.svg',
  },
  {
    key: 'phishing',
    name: 'PHISHING',
    icon: 'assets/icons/threat-check/phishing.svg',
  },
  {
    key: 'impersonate',
    name: 'IMPERSONATE',
    icon: 'assets/icons/threat-check/impersonate.svg',
  },
  {
    key: 'compromised-system',
    name: 'COMPROMISED_SYSTEM',
    icon: 'assets/icons/threat-check/compromised.svg',
  },
  {
    key: 'port-anomaly',
    name: 'ANOMALY_OPEN_PORT',
    icon: 'assets/icons/threat-check/port-anomaly.svg',
  },
  {
    key: 'web-encryption',
    name: 'WEB_ENCRYPTION',
    icon: 'assets/icons/threat-check/web-encryption.svg',
  },
  {
    key: 'http-header-security',
    name: 'HTTP_HEADER_SECURITY',
    icon: 'assets/icons/threat-check/http-header-security.svg',
  },
  {
    key: 'application-security',
    name: 'APPLICATION_SECURITY',
    icon: 'assets/icons/threat-check/application-security.svg',
  },
  {
    key: 'vulnerability',
    name: 'WEB_VULNERABILITY',
    icon: 'assets/icons/threat-check/vulnerability.svg',
  },
];

const createIndustry = (desc_vi: string, desc_en: string, value: string): any => ({ desc_vi, desc_en, value });

export const LIST_INDUSTRY = [
  createIndustry('Ngân hàng', 'Banking', 'banking'),
  createIndustry('Nông nghiệp', 'Agriculture', 'agriculture'),
  createIndustry('Hàng không vũ trụ', 'Aerospace', 'aerospace'),
  createIndustry('Ô tô', 'Automotive', 'automotive'),
  createIndustry('Truyền thông', 'Communications', 'communications'),
  createIndustry('Xây dựng', 'Construction', 'construction'),
  createIndustry('Quốc phòng', 'Defence', 'defence'),
  createIndustry('Giáo dục', 'Education', 'education'),
  createIndustry('Năng lượng', 'Energy', 'energy'),
  createIndustry('Giải trí', 'Entertainment', 'entertainment'),
  createIndustry('Tài chính-dịch vụ', 'Financial-Services', 'financial-services'),
  createIndustry('Chính phủ-quốc gia', 'Government-National', 'government-national'),
  createIndustry('Chính phủ-khu vực', 'Government-Regional', 'government-regional'),
  createIndustry('Chính phủ-địa phương', 'Government-Local', 'government-local'),
  createIndustry('Chính phủ-dịch vụ công', 'Government-Public services', 'government-public-services'),
  createIndustry('Chăm sóc sức khỏe', 'Healthcare', 'healthcare'),
  createIndustry('Khách sạn-giải trí', 'Hospitality-Leisure', 'hospitality-leisure'),
  createIndustry('Cơ sở hạ tầng', 'Infrastructure', 'infrastructure'),
  createIndustry('Bảo hiểm', 'Insurance', 'insurance'),
  createIndustry('Sản xuất', 'Manufacturing', 'manufacturing'),
  createIndustry('Khai thác', 'Mining', 'mining'),
  createIndustry('Phi lợi nhuận', 'Non-profit', 'non-profit'),
  createIndustry('Dược phẩm', 'Pharmaceuticals', 'pharmaceuticals'),
  createIndustry('Bán lẻ', 'Retail', 'retail'),
  createIndustry('Công nghệ', 'Technology', 'technology'),
  createIndustry('Viễn thông', 'Telecommunications', 'telecommunications'),
  createIndustry('Giao thông vận tải', 'Transportation', 'transportation'),
  createIndustry('Tiện ích', 'Utilities', 'utilities'),
  createIndustry('Lĩnh vực khác', 'Others', 'others'),
];
