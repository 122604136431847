import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, FormBuilder, Form } from '@angular/forms';
import { ReportService } from '@ti/services/report.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

interface Field {
  label: string;
  type: string;
  key: string;
  placeholder?: string;
  required?: boolean;
  pattern?: string | RegExp;
  options?: {
    label: string;
    value: string;
  }[];
}

const regexCaptcha: RegExp = /\S*/;

@Component({
  selector: 'ti-modal-report',
  templateUrl: './modal-report.component.html',
  styleUrls: ['./modal-report.component.scss'],
})
export class ModalReportComponent implements OnInit {
  formReport: FormGroup;
  lang = 'vi';
  listFields: Field[] = [
    {
      label: 'CATEGORY',
      key: 'category',
      type: 'select',
      options: [
        {
          label: 'False positive',
          value: 'false-positive',
        },
      ],
      placeholder: '',
      required: true,
    },
    {
      label: 'Domain/Url/IP',
      key: 'link',
      type: 'text',
      placeholder: '',
      required: true,
    },
    {
      label: 'EMAIL',
      type: 'text',
      key: 'email',
      placeholder: '',
      required: true,
    },
    {
      label: 'REASON',
      type: 'textarea',
      key: 'reason',
      placeholder: ``,
    },
    {
      label: 'CAPTCHA',
      type: 'captcha',
      key: 'captcha',
      placeholder: 'ENTER CAPTCHA CODE HERE',
      required: true,
    },
  ];
  submitted = false;
  imagePath: any;
  captchaBase64: string;
  sending = false;
  invalidCaptcha = false;
  invalidLink = false;
  notExistLink = false;
  onChange: (data) => void;

  noWhitespaceValidator(control: FormControl): any {
    const isWhitespace = (control.value?.replace(/<(?!img)[^>]+>/gi, '').trim() || '').trim()?.length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  validateCaptcha(control: FormControl): any {
    return control.value.indexOf(' ') < 0;
  }

  validateLink(control: FormControl) {
    const TI_REGEX_URL: RegExp =
      /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    const TI_REGEX_DOMAIN: RegExp = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
    const TI_REGEX_IP: RegExp =
      /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?=\s*netmask)$/;
    const TI_REGEX_URL_2 =
      /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/;

    return (
      // url
      ((control?.value?.startsWith('http') ||
        control?.value?.startsWith('tcp') ||
        control?.value?.startsWith('udp') ||
        control?.value?.startsWith('ftp')) &&
        TI_REGEX_URL.test(control.value)) ||
        // domain
        TI_REGEX_DOMAIN.test(control.value) ||
        // url //
        TI_REGEX_URL_2.test(control.value) ||
        // ip
        TI_REGEX_IP.test(control.value)
        ? null
        : {
            validateInput: {
              valid: false,
            },
          }
    );
  }

  constructor(
    private modalRef: NgbActiveModal,
    private reportService: ReportService,
    private sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private router: Router,
    private fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.formReport = new FormGroup({
      category: new FormControl('', [Validators.required]),
      link: new FormControl('', [Validators.required, this.validateLink, this.noWhitespaceValidator]),
      email: new FormControl('', [Validators.email, Validators.required, this.noWhitespaceValidator]),
      reason: new FormControl(''),
      captcha: new FormControl('', [Validators.required, Validators.pattern(regexCaptcha), this.validateCaptcha]),
    });

    this.reportService.getCaptchaBase64().subscribe((res: any) => {
      this.captchaBase64 = res['captcha-image'];
      this.imagePath = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${res['captcha-image']}`);
    });
    this.lang = localStorage.getItem('lang') ?? 'vi';
  }

  ngOnDestroy(): void {}

  onCloseModal() {
    this.modalRef.close();
  }

  handleClose() {
    this.onCloseModal();
  }

  handleSendReport() {
    this.submitted = true;
    this.invalidCaptcha = false;
    this.invalidLink = false;
    this.notExistLink = false;
    const body = {
      ...this.formReport.value,
      image: this.captchaBase64,
      lang: this.lang,
    };
    if (this.formReport.valid) {
      this.sending = true;
      this.reportService.sendReport(body).subscribe(
        (res: any) => {
          this.sending = false;
          this.handleClose();
          const message = this.lang === 'en' ? 'Report sent successfully' : 'Gửi báo cáo thành công';
          this.toastrService.success(message, '');
        },
        error => {
          if (error.error.detail === 'Invalid captcha') {
            this.invalidCaptcha = true;
          } else if (error.error.detail?.[0]?.msg === 'Link error') {
            this.invalidLink = true;
          } else if (error.error.detail === 'LINK_NOT_EXIST') {
            this.notExistLink = true;
          } else {
            const message = this.lang === 'en' ? 'Report sent failed' : 'Gửi báo cáo thất bại';
            this.toastrService.error(message, '');
            this.handleClose();
          }
          this.sending = false;
          this.handleRefreshCaptcha();
        },
      );
    }
  }

  registerFormControl(key: string) {
    return this.formReport.controls[key];
  }

  handleChangeInputCaptcha(event): void {
    this.invalidCaptcha = false;
  }

  handleChangeInputLink(event, key): void {
    if (key === 'link') {
      this.notExistLink = false;
    }
  }

  handleRefreshCaptcha() {
    this.reportService.getCaptchaBase64().subscribe((res: any) => {
      this.captchaBase64 = res['captcha-image'];
      this.imagePath = this.sanitizer.bypassSecurityTrustUrl(`data:image/png;base64, ${res['captcha-image']}`);
    });
  }

  checkValidField(key): boolean {
    if (this.formReport.controls[key]) {
      return this.formReport.controls[key].status === 'VALID';
    }
    return false;
  }
}
