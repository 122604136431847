export class AppEnvironmentService {

  // The values that are defined here are the default values that can
  // be overridden by env.js
  linkToLogin = 'https://platform.staging.ti.visc.com/#/login';
  linkToRegister = 'https://platform.staging.ti.visc.com/#/register';
  facebook = 'https://www.facebook.com/anninhmangviettel';
  website = 'https://viettelcybersecurity.com/';
  youtube = 'https://www.youtube.com/@ViettelCyberSecurity';
  awpg = 'https://apwg.org/sponsor-solutions/sponsoring-members/';
  first = 'https://www.first.org/members/teams/viettel_cyber_security';
  microsoft = 'https://www.microsoft.com/en-us/msrc/mapp';
  virustotal = 'https://support.virustotal.com/hc/en-us/articles/115002146809-Contributors';
  techEmail = 'cyberthreat@viettel.com.vn';
  saleEmail = 'vcs.sales@viettel.com.vn';
  hotline = '0326.360.360';
  report = 'http://landing.ti.visc.com/api/v1/api/report';
  threatCheck = 'http://api.staging.ti.visc.com/identity-service/public/user'
  recaptchaApiKey = '';
  enableRegister = false;
  enableThreatCheck = true;
  mixPanel = 'ca858dd5433c61a232567c2013fcbb4c';


  constructor() {
  }

}
