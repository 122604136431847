import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TiIconModule } from '../features/ti-icon/ti-icon.module';
import { HeaderComponent } from './components/header/header.component';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import { NumberFormatPipe } from './pipes/NumberFormat.pipe';
import { NgxPopperModule } from 'ngx-popper';
import { RouterModule } from '@angular/router';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CountUpDirective } from './directives/counter-up.directive';
import { ModalReportComponent } from './components/modal-report/modal-report.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { SpeakWithOurExpertComponent } from './components/speak-with-our-expert/speak-with-our-expert.component';
import { TrialAlertComponent } from './components/trial-alert/trial-alert.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { PhotoBannerComponent } from './components/photo-banner/photo-banner.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb=' + new Date().getTime());
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'vi',
    }),
    NgxPopperModule.forRoot({
      applyClass: 'global-popper-content',
      applyArrowClass: 'global-popper-arrow',
    }),
    TiIconModule,
    // FormBuilder,
    NgSelectModule,
    FormsModule,
    CarouselModule,
    ReactiveFormsModule,
  ],
  exports: [
    TiIconModule,
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    NumberFormatPipe,
    CountUpDirective,
    SpeakWithOurExpertComponent,
    TrialAlertComponent,
    StatisticsComponent,
    PhotoBannerComponent,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    BreadcrumbComponent,
    NumberFormatPipe,
    CountUpDirective,
    ModalReportComponent,
    ContactModalComponent,
    SpeakWithOurExpertComponent,
    TrialAlertComponent,
    RecaptchaComponent,
    RecaptchaComponent,
    StatisticsComponent,
    PhotoBannerComponent,
    SuccessModalComponent,
  ],
})
export class SharedModule {}
