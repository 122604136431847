import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { PORTFOLIO } from '@ti/constants';
import { MixpanelService } from '@ti/mixpanel.service';

@Component({
  selector: 'ti-speak-with-our-expert',
  templateUrl: './speak-with-our-expert.component.html',
  styleUrls: ['./speak-with-our-expert.component.scss'],
})
export class SpeakWithOurExpertComponent implements OnInit {
  items = PORTFOLIO;
  constructor(private modalService: NgbModal, private mixpanelService: MixpanelService) {}

  ngOnInit() {}

  contactUs(): void {
    this.modalService.open(ContactModalComponent);
  }

  mixpanelTrack() {
    this.mixpanelService.track('speak-with-expert');
  }
}
