import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
	transform(value: any): string {
		if (value === undefined || value === null || value === '') {
			return '';
		}
		return Number(value)
			.toFixed(0)
			.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	}
}
