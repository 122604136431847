import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'ti-photo-banner',
  templateUrl: './photo-banner.component.html',
  styleUrls: ['./photo-banner.component.scss'],
})
export class PhotoBannerComponent implements OnInit {
  photoBannerOptions: OwlOptions = {
    loop: true,
    autoplaySpeed: 1000,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    navSpeed: 300,
    items: 12,
  };
  constructor() {}

  ngOnInit(): void {}
}
